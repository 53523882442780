import {
    useTranslate,
    IResourceComponentsProps,
  } from "@pankod/refine-core";
  import {
    List,
    Table,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    Space,
    EditButton,
    DeleteButton,
    ShowButton,
  } from "@pankod/refine-antd";

  import { formatDateTime } from "utils/dates";
  import { Location } from "interfaces/location";
  
  export const LocationList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
  
    const { tableProps, sorter } = useTable<Location>({
      initialSorter: [
        {
          field: "name",
          order: "desc",
        },
      ],
    });

    const token = localStorage.getItem("refine-auth");
    let initialIsSuperuser = false;

    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      
      initialIsSuperuser = payload.is_superuser;
    }
  
    
    return (
      <List canCreate={initialIsSuperuser ? true : false}>
        <Table {...tableProps} rowKey="id">
          {/* <Table.Column
            dataIndex="ID"
            key="id"
            title="ID"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          /> */}
          <Table.Column
            dataIndex="Name"
            key="name"
            title={"Name"}
            render={(value) => <TextField value={value} />}
            // defaultSortOrder={getDefaultSortOrder("name", sorter)}
            // sorter
          />
          <Table.Column
            dataIndex="County"
            key="county"
            title={"County"}
            render={(value) => <TextField value={value} />}
            // defaultSortOrder={getDefaultSortOrder("county", sorter)}
            // sorter
          />
          <Table.Column
            dataIndex="CanOpenGate"
            key="CanOpenGate"
            title={"Can Open Gate?"}
            render={(value) => <TextField value={value ? "True" : "False"} />}
            // defaultSortOrder={getDefaultSortOrder("CanOpenGate", sorter)}
            // sorter
          />
          <Table.Column
            dataIndex="location_permanent_access_limit"
            key="location_permanent_access_limit"
            title={"Location Permanent Access Limit"}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="location_temporary_access_limit"
            key="location_temporary_access_limit"
            title={"Location Temporary Access Limit"}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="location_temporary_time_limit"
            key="location_temporary_time_limit"
            title={"Location Temporary Time Limit"}
            render={(value) => <TextField value={value} />}
          />
          {/* <Table.Column
            dataIndex="IsParking"
            key="IsParking"
            title={"Is Parking?"}
            render={(value) => <TextField value={value ? "True" : "False"} />}
            defaultSortOrder={getDefaultSortOrder("IsParking", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="price_per_hour"
            key="price_per_hour"
            title={"Price Per Hour"}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="fraction_price"
            key="fraction_price"
            title={"Fraction Price"}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="price_per_day"
            key="price_per_day"
            title={"Price Per Day"}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="total_spots"
            key="total_spots"
            title={"Total Spots"}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="available_spots"
            key="available_spots"
            title={"Available Spots"}
            render={(value) => <TextField value={value} />}
          /> */}
          <Table.Column
            dataIndex="UpdatedAt"
            key="updatedAt"
            title={"UpdatedAt"}
            render={(value) => {
              const formattedDate = formatDateTime(value);
              return <TextField value={formattedDate} />;
            }}
            defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="CreatedAt"
            key="createdAt"
            title={"CreatedAt"}
            render={(value) => {
              const formattedDate = formatDateTime(value);
              return <TextField value={formattedDate} />;
            }}
            defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
            sorter
          />
          <Table.Column<Location>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.ID} />
                {/* <ShowButton hideText size="small" recordItemId={record.ID} /> */}
                {initialIsSuperuser && (
                <DeleteButton hideText size="small" recordItemId={record.ID} />
                )}
              </Space>
            )}
          />
        </Table>
      </List>
    );
  };
  