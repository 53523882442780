import {
  useTranslate,
  IResourceComponentsProps,
  useList,
  useInvalidate,
} from "@pankod/refine-core";
import axios from "axios";
import { API_URL } from "const";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  useForm,
} from "@pankod/refine-antd";
import { UserHouse } from "interfaces/userhouse";
import { House } from "interfaces/house";
import { User } from "interfaces/user";
import { Button, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { formatDateTime } from "utils/dates";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const UserHouseList: React.FC<IResourceComponentsProps> = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description,
    });
  };
  const t = useTranslate();
  const token = localStorage.getItem("refine-auth");
  let initialIsSuperuser = false;

  if (token) {
    const payload = JSON.parse(atob(token.split(".")[1]));
    
    initialIsSuperuser = payload.is_superuser;
  }

  const invalidate = useInvalidate();
  const { tableProps, sorter } = useTable<UserHouse>({
    initialSorter: [
      {
        field: "name",
        order: "desc",
      },
    ],
  });

  const housesQueryResult = useList<House>({
    resource: "houses",
  });

  const usersQueryResult = useList<User>({
    resource:"users",
  });

  const getUser = (id:string):string => {
    if(usersQueryResult.data?.data && usersQueryResult.data.data.length>0)
    {
        const data = usersQueryResult.data.data;
        for (let x:number=0;x<data.length;x++)
        {
          var user:User = data[x];
          if (user.ID === id) {
            return user.Name;
          }
        }
    }
    return "";
  }

  const getUserPhone = (id:string):string => {
    if(usersQueryResult.data?.data && usersQueryResult.data.data.length>0)
    {
        const data = usersQueryResult.data.data;
        for (let x:number=0;x<data.length;x++)
        {
          var user:User = data[x];
          if (user.ID === id) {
            return user.PhoneNumber;
          }
        }
    }
    return "";
  }

  const getHouseAddress = (id:string):string => {
    if(housesQueryResult.data?.data && housesQueryResult.data.data.length>0)
    {
        const data = housesQueryResult.data.data;
        for (let x:number=0;x<data.length;x++)
        {
          var house:House = data[x];
          if (house.ID === id) {
            return house.Address;
          }
        }
    }
    return "";
  }

  const deleteUserHouse = (record: any) => {
    const token = localStorage.getItem("refine-auth");
    axios.post(`${API_URL}/delete_user_house`, {
      user_id: record.user_id,
      house_id: record.house_id
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(req => {
      if (req.status === 200 || req.status === 201) {
        openNotificationWithIcon("success", "Success", "User house deleted successfully.");
        // refetch UserHouses data
        invalidate({
          resource: "userhouses",
          invalidates: ["list", "many"]
        });
      }        
    }).catch(error => {
      openNotificationWithIcon("error", "Error", "Failed to delete user house.");
    });
  }

  
  return (
    <>
    {contextHolder}
    <List title={"House Information"}>
      <Table {...tableProps} rowKey="id">
        {/* <Table.Column
          dataIndex="ID"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        /> */}
        {/* <Table.Column
          dataIndex="user_id"
          key="userId"
          title={"User ID"}
          render={(value) => <TextField value={value} />}
          // defaultSortOrder={getDefaultSortOrder("name", sorter)}
          // sorter
        /> */}
        <Table.Column
            dataIndex="user_id"
            key="userName"
            title={t("User Name")}
            render={(value) => <TextField value={getUser(value)} />}
            defaultSortOrder={getDefaultSortOrder("user", sorter)}
          />
          <Table.Column
            dataIndex="user_id"
            key="phoneNumber"
            title={t("Phone Number")}
            render={(value) => <TextField value={getUserPhone(value)} />}
            defaultSortOrder={getDefaultSortOrder("user", sorter)}
          />
        {/* <Table.Column
          dataIndex="house_id"
          key="houseId"
          title={"House ID"}
          render={(value) => <TextField value={value} />}
          // defaultSortOrder={getDefaultSortOrder("email", sorter)}
          // sorter
        /> */}
        <Table.Column
          dataIndex="house_id"
          key="houseAddress"
          title={t("House Address")}
          render={(value) => <TextField value={getHouseAddress(value)} />}
          // defaultSortOrder={getDefaultSortOrder("role", sorter)}
          // sorter
        />
        <Table.Column
          dataIndex="house_owner"
          key="houseOwner"
          title={"House Owner?"}
          render={(value) => <TextField value={value ? "Owner" : "Not Owner"} />}
          // defaultSortOrder={getDefaultSortOrder("super_user", sorter)}
          // sorter
        />
        {/* <Table.Column
          dataIndex="UpdatedAt"
          key="updatedAt"
          title={t("users.fields.updatedAt")}
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />*/}
        <Table.Column
            dataIndex="CreatedAt"
            key="createdAt"
            title={"CreatedAt"}
            // render={(value) => <DateField value={value} format="LLL" />}
            render={(value) => {
              const formattedDate = formatDateTime(value);
              return <TextField value={formattedDate} />;
            }}
            defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
            sorter
          />
        
        <Table.Column<UserHouse>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
                <>
                {/* <EditButton hideText size="small" recordItemId={record.ID} /> */}
                {/* <DeleteButton 
                  hideText 
                  size="small" 
                  // recordItemId={record.ID} 
                  onClick={() => deleteUserHouse(record)}

                /> */}
                <Button 
                  style={{color: "#FF0000", borderColor: "#FF0000"}}
                  type="default" size="small" onClick={() => deleteUserHouse(record)}
                  icon={<DeleteOutlined color="#FF0000"/>}
                />
                </>
            </Space>
          )}
        />
      </Table>
    </List>
    </>
  );
};
