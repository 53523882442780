import React, { useEffect, useState } from "react";
import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
  LoginPage,
} from "@pankod/refine-antd";

import "styles/antd.less";
import routerProvider from "@pankod/refine-react-router-v6";
import { authProvider } from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";
import { UserList, UserCreate, UserEdit, UserShow } from "pages/users";
import { LocationList, LocationCreate, LocationEdit } from "pages/locations";
import { HouseList, HouseCreate, HouseEdit } from "pages/houses";
import { AccessCreate, AccessEdit, AccessList } from "pages/accesses";
import { UserHouseCreate, UserHouseEdit, UserHouseList } from "pages/userhouse";
import { UserLocationCreate, UserLocationList, UserLocationEdit } from "pages/userlocations";
import { GateOpeningList } from "pages/gateopening";
import { CustomerVisitList } from "pages/customervisits";
import { GateList, GateCreate, GateEdit } from "pages/gate";
import { PaymentsDashboard } from "pages/payments";
import { useTranslation } from "react-i18next";
import { Login } from "pages/login";

import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";

import { API_URL } from "const";

function App() {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("refine-auth");
  let initialIsSuperuser = false;

  if (token) {
    const payload = JSON.parse(atob(token.split(".")[1]));
    
    initialIsSuperuser = payload.is_superuser;
  }
  
  const [resources, setResources] = useState<any[]>([
    {
      name: "users",
      list: UserList,
      create: UserCreate,
      edit: UserEdit,
      show: UserShow,
    },
    {
      name: "houses",
      list: HouseList,
      create: HouseCreate,
      edit: HouseEdit,
    },
    {
      name: "userhouses",
      list: UserHouseList,
      create: UserHouseCreate,
      edit: UserHouseEdit,
    },
    {
      name: "accesses",
      list: AccessList,
      create: AccessCreate,
      edit: AccessEdit,
    },
    {
      name: "locations",
      list: LocationList,
      create: LocationCreate,
      edit: LocationEdit,
    },
    {
      name: "userlocations",
      list: UserLocationList,
      create: UserLocationCreate,
      edit: UserLocationEdit,
    },
    {
      name: "gateopenings",
      list: GateOpeningList,
    },
    {
      name: "gates",
      list: GateList,
      create: GateCreate,
      edit: GateEdit,
    },
    {
      name: "customervisits",
      list: CustomerVisitList,
    },
  ]);

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <Refine
      notificationProvider={notificationProvider}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      routerProvider={{
        ...routerProvider,
        routes: [{ element: <PaymentsDashboard />, path: "/payments" }],
      }}
      dataProvider={dataProvider(`${API_URL}`)}
      authProvider={authProvider}
      LoginPage={Login}
      resources={[... initialIsSuperuser ? resources : resources.filter(r => 
        r.name !== "userlocations" && 
        r.name !== "gates" &&
        r.name !== "customervisits"
      )]}
      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
      i18nProvider={i18nProvider}
    />
  );
}

export default App;
