import {
  useTranslate,
  IResourceComponentsProps,
  useList,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";

import { formatDateTime } from "utils/dates";

import { House } from "interfaces/house";
import { Location } from "interfaces/location";
import { User } from "interfaces/user";

export const HouseList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const token = localStorage.getItem("refine-auth");
  let initialIsSuperuser = false;

  if (token) {
    const payload = JSON.parse(atob(token.split(".")[1]));
    
    initialIsSuperuser = payload.is_superuser;
  }
  const { tableProps, sorter } = useTable<House>({
    initialSorter: [
      {
        field: "name",
        order: "desc",
      },
    ],
  });

  const locationsQueryResult = useList<Location>({
    resource: "locations",
  });

  const usersQueryResult = useList<User>({
    resource: "users",
  });

  const getLocationName = (id: string): string => {
    if (locationsQueryResult.data?.data && locationsQueryResult.data.data.length > 0) {
      const data = locationsQueryResult.data.data;
      for (let x: number = 0; x < data.length; x++) {
        var loc: Location = data[x];
        if (loc.ID === id) {
          return loc.Name;
        }
      }
    }
    return "";
  }

  const getUser = (id: string): string => {
    if (usersQueryResult.data?.data && usersQueryResult.data.data.length > 0) {
      const data = usersQueryResult.data.data;
      for (let x: number = 0; x < data.length; x++) {
        var user: User = data[x];
        console.log(user);
        if (user.ID === id) {
          return user.Name;
        }
      }
    }
    return "";
  }


  return (
    <List canCreate={initialIsSuperuser ? true : false}>
      <Table {...tableProps} rowKey="id">
        {/* <Table.Column
          dataIndex="ID"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        /> */}
        <Table.Column
          dataIndex="Address"
          key="address"
          title={"Address"}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("address", sorter)}
          sorter
        />
        {/* <Table.Column
          dataIndex="LocationID"
          key="LocationID"
          title={t("Location ID")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("location", sorter)}
        /> */}
        <Table.Column
          dataIndex="LocationID"
          key="LocationID"
          title={t("Location Name")}
          render={(value) => <TextField value={getLocationName(value)} />}
          defaultSortOrder={getDefaultSortOrder("location", sorter)}
        />
        {/*<Table.Column
          dataIndex="UserID"
          key="UserID"
          title={t("User Name")}
          render={(value) => <TextField value={getUser(value)} />}
          defaultSortOrder={getDefaultSortOrder("user", sorter)}
        /> */}
        <Table.Column
          dataIndex="UpdatedAt"
          key="updatedAt"
          title={t("users.fields.updatedAt")}
          render={(value) => {
            const formattedDate = formatDateTime(value);
            return <TextField value={formattedDate} />;
          }}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="CreatedAt"
          key="createdAt"
          title={t("users.fields.createdAt")}
          render={(value) => {
            const formattedDate = formatDateTime(value);
            return <TextField value={formattedDate} />;
          }}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        {initialIsSuperuser && (
        <Table.Column<House>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
                <>
                <EditButton hideText size="small" recordItemId={record.ID} />
                <DeleteButton hideText size="small" recordItemId={record.ID} />
                </>
              {/* <ShowButton hideText size="small" recordItemId={record.ID} /> */}
            </Space>
          )}
          />
        )}
      </Table>
    </List>
  );
};
